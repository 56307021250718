html, body {
    background-color: rgb(224, 226, 228);
}

::-webkit-scrollbar{
    width: 7px !important;   
    height: 7px !important;    
}

::-webkit-scrollbar-thumb{
    background-color: #c1c1c1 !important;
    border-radius: 20px !important;    
}